<!-- eslint-disable -->
<template>
  <div style="border-top: 1px solid #eeeeee">
    <section
      class="has-text-centered hero is-medium is-white"
      style="background-color: transparent !important"
    >
      <div class="hero-body">
        <div class="container">
          <div class="has-text-centered" style="margin-bottom: 60px">
            <svg
              fill="none"
              height="109"
              viewBox="0 0 120 109"
              width="120"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43.3645 29.1714H23.258C16.6656 29.1714 11.2269 35.2691 11.2269 42.8504V77.1305C11.2269 78.1194 11.8862 78.7784 12.8749 78.7784H19.9615V103.005C19.9615 106.136 22.4336 108.609 25.565 108.609H41.2216C44.353 108.609 46.8251 106.136 46.8251 103.005V78.9433H53.9117C54.9007 78.9433 55.5597 78.284 55.5597 77.2953V42.8504C55.5602 35.2691 50.1215 29.1714 43.3645 29.1714ZM52.264 75.6471H46.8253V58.1774C46.8253 57.1885 46.166 56.5294 45.1773 56.5294C44.1886 56.5294 43.5294 57.1887 43.5294 58.1774V103.17C43.5294 104.488 42.5404 105.477 41.2221 105.477H34.9593V78.4488C34.9593 77.4599 34.3001 76.8008 33.3114 76.8008C32.3226 76.8008 31.6634 77.4601 31.6634 78.4488V105.477H25.5652C24.2469 105.477 23.258 104.488 23.258 103.17V58.1774C23.258 57.1885 22.5987 56.5294 21.61 56.5294C20.6213 56.5294 19.962 57.1887 19.962 58.1774V75.6471H14.5231V42.8504C14.5231 37.0821 18.4786 32.4676 23.258 32.4676H43.3645C48.3087 32.4676 52.2642 37.0821 52.2642 42.8504V75.6471H52.264Z"
                fill="#666666"
              />
              <path
                d="M33.3112 27.0284C40.7274 27.0284 46.8254 20.9305 46.8254 13.5142C46.8254 6.09792 40.7277 0 33.3112 0C25.8949 0 19.9618 6.09792 19.9618 13.5142C19.9618 20.9307 25.8949 27.0284 33.3112 27.0284ZM33.3112 3.29619C38.9146 3.29619 43.5292 7.91073 43.5292 13.5142C43.5292 19.1177 38.9146 23.7322 33.3112 23.7322C27.7077 23.7322 23.0932 19.1177 23.0932 13.5142C23.258 7.91073 27.7077 3.29619 33.3112 3.29619Z"
                fill="#666666"
              />
              <path
                d="M108.134 31.808C108.628 31.808 108.958 31.6431 109.288 31.3135L119.506 20.4362C120.165 19.7769 120.165 18.7882 119.506 18.1289L109.288 7.25145C108.958 6.92181 108.463 6.75699 108.134 6.75699H101.047V1.64799C101.047 0.659063 100.388 0 99.3992 0H90.4995C89.5106 0 88.8515 0.659282 88.8515 1.64799V6.92181H73.3597C72.3708 6.92181 71.7117 7.58109 71.7117 8.56979V30.3244C71.7117 31.3133 72.371 31.9723 73.3597 31.9723H88.8515V41.2016H78.6335C78.1391 41.2016 77.8094 41.3665 77.4798 41.6961L67.2616 52.4088C66.6023 53.0681 66.6023 54.0568 67.2616 54.716L77.4796 65.5933C77.8092 65.923 78.3037 66.0878 78.6333 66.0878H88.8513V105.477H70.8872C69.8982 105.477 69.2392 106.136 69.2392 107.125C69.2392 108.114 69.8985 108.773 70.8872 108.773C103.519 108.773 82.5886 108.773 115.88 108.773C116.869 108.773 117.528 108.114 117.528 107.125C117.528 106.136 116.868 105.477 115.88 105.477H101.047V66.0878H113.408C114.397 66.0878 115.056 65.4285 115.056 64.4398V42.6852C115.056 41.6963 114.396 41.0373 113.408 41.0373H101.047V31.808H108.134ZM92.1475 3.29619H97.7509V6.92203H92.1475V3.29619ZM97.7509 105.477H92.1475V66.0878H97.7509V105.477ZM111.76 44.3332V62.7916H79.2926L70.7226 53.5623L79.2926 44.333C81.435 44.333 91.8181 44.333 99.3992 44.333H111.76V44.3332ZM92.1475 41.037V31.8077H97.7509V41.037H92.1475ZM75.0075 28.6766V10.218H107.474L116.044 19.4473L107.474 28.6766H75.0075Z"
                fill="#666666"
              />
              <path
                d="M4.20026 15.7095H7.62952V19.0352H4.20026V15.7095ZM0 7.42402C0.0920605 5.23758 0.855396 3.6879 2.29001 2.77497C3.19527 2.19192 4.30767 1.90039 5.6272 1.90039C7.36101 1.90039 8.79945 2.31466 9.94254 3.14321C11.0933 3.97175 11.6687 5.19923 11.6687 6.82563C11.6687 7.82295 11.4193 8.663 10.9207 9.34578C10.6292 9.76006 10.0691 10.2894 9.24057 10.9338L8.42354 11.5667C7.97858 11.912 7.68322 12.3147 7.53746 12.775C7.44539 13.0666 7.39553 13.5192 7.38786 14.1329H4.28081C4.32684 12.8364 4.44959 11.9427 4.64906 11.4517C4.84852 10.953 5.36253 10.3815 6.19107 9.73704L7.03112 9.08111C7.3073 8.87397 7.52978 8.64766 7.69856 8.40216C8.00543 7.98022 8.15886 7.51608 8.15886 7.00975C8.15886 6.4267 7.98625 5.89735 7.64102 5.42171C7.30347 4.93839 6.68206 4.69673 5.7768 4.69673C4.88688 4.69673 4.25396 4.99209 3.87805 5.58281C3.50981 6.17353 3.32569 6.78727 3.32569 7.42402H0Z"
                fill="#009500"
              />
            </svg>
          </div>
          <h1
            class="has-text-centered has-text-primary has-text-weight-bold is-4 title"
            style="margin-bottom: 1.5rem"
          >
            {{ $t(' Error 404') }}
          </h1>
          <p class="is-size-6-5" style="margin-bottom: 1.5rem">
            {{ $t('desc') }}
          </p>
          <p>
            <router-link
              class="ant-btn ant-btn-primary has-text-white"
              style="min-width: 170px"
              to="/"
            >
              {{ $t('back') }}
            </router-link>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<i18n>
{
"en": {
"Error 404": "Error 404",
"desc": "We can’t find the page you are looking for.",
"back": "Back to home page"
},
"ja": {
"Error 404": "エラー 404",
"desc": "あなたが探しているページが見つからない。",
"back": "ホームページへ戻る"
}
}
</i18n>

<script>
export default {
  name: 'NotFound',
};
</script>
